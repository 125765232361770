:root {
    /** Cookie Consent variables **/
    --cc-secondary-color: #3f3f3f !important;
    --cc-btn-primary-bg: #f9ae3b !important;
    --cc-btn-primary-hover-bg: #f9ae3bf0 !important;
    --cc-btn-border-radius: 0.7rem !important;
    --cc-btn-secondary-bg: #fff !important;
    --cc-btn-secondary-color: var(--cc-btn-primary-bg) !important;
    --cc-btn-secondary-border-color: var(--cc-btn-primary-bg) !important;
    --cc-btn-secondary-hover-bg: #fff !important;
    --cc-btn-secondary-hover-color: #f9ae3bf0 !important;
    --cc-btn-secondary-hover-border-color: --cc-btn-primary-bg !important;
}


/* Cookie Consent Custom Styles */
#cc-main {
    --cc-font-family: 'Montserrat', 'Arial', sans-serif;
}

#cc-main .cm__title {
    border-bottom: 1px solid var(--cc-separator-border-color);
    padding-bottom: 0.7rem !important;
}

#cc-main .cm__footer {
    --cc-footer-color: #012d6c;
}